import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import illustration from "../../../assets/images/appImages/illustration.png";
import founder1 from "../../../assets/images/appImages/founder_1.png";
import founder2 from "../../../assets/images/appImages/founder_2.png";


const CustomBeautyTitle = ({ title }: { title: string }) => {
  return <h2 className="custom-esb-title">{title}</h2>;
};

function WhoWeAre() {
  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content who-we-are-page pb-0">
        <h1 className="custom-page-title">Qui sommes-nous ?</h1>
        <div className="who-we-are">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="illustration-container">
                  <img src={illustration} alt="image" />
                </div>
              </div>
              <div className="col-md-6">
                <CustomBeautyTitle title="Qui sommes nous?" />
                <h3 className="custom-title">KARPOPHOREO</h3>
                <div className="custom-content">
                  Est une marque de cosmétiques qui propose des produits naturels et made in Africa. Que vous ayez une peau sensible/ atopique ou normale nous avons la solution.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="plateform-founders">
          <div className="container">
            <CustomBeautyTitle title="Qui sommes nous" />
            <h3 className="custom-title"></h3>
            <div className="row pt-4">
              <div className="col-md-6 col-lg-6 mb-4 col-wrapper">
                <div className="founder-card">
                  <div className="pb-3 d-flex justify-content-center photo-container">
                    <span className="photo-wrapper">
                      <img src={founder1} alt="karpophoreo-founder-photo" />
                    </span>
                  </div>
                  <span className="name">Emilie Ndiéké SIBY</span>
                  <span className="role">Fondatrice</span>
                  <span className="pt-2 description">
                    Diplômée en administration et gestion des entreprises et en stylisme-modélisme.
                    Passionnée de l'Afrique, de ses richesses et de cosmétiques naturels
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 mb-4 col-wrapper">
                <div className="founder-card">
                  <div className="pb-3 d-flex justify-content-center photo-container">
                    <span className="photo-wrapper">
                      <img src={founder2} alt="karpophoreo-founder-photo" />
                    </span>
                  </div>
                  <span className="name">Sophie SIBY</span>
                  <span className="role">partenaire</span>
                  <span className="pt-2 description">

                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="dots_deco dots_deco__top"></div>
          <div className="dots_deco dots_deco__bottom"></div>
        </div>
      </div>
      <FrontFooter />
    </div>
  );
}

export default WhoWeAre;
