import React from "react";
import { AlertInfo } from "../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import { FaTrash } from "react-icons/fa";
import { getImage } from "../../../../utils/Utils";
import Empty from "../../../../assets/images/appImages/category.png";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { Color } from "../../../../utils/theme";
import Swal from "sweetalert2";
import {
  CartItemType,
  decrement,
  increment,
  removeCartItem,
} from "../../../../redux/slice/useCartSlice";
import {
  useAddProductCartMutation,
  useDeleteProductCartMutation,
} from "../../../../utils/api/product/product.api";
import { Product } from "../../../../utils/api/product/product.type";
import { formatCurrency } from "../../../../utils/Utils";
import { getVendeur } from "../../../HomePage/HomePage";


function PanierItemTable() {
  const Vendeur = getVendeur();

  const { user } = useAppSelector((s) => s?.user);
  const [deleteProduct] = useDeleteProductCartMutation();
  // const { data: carts } = useGetCartByUserQuery(user?.slug);
  // const { data, isLoading } = useGetListProductQuery();
  const dispatch = useAppDispatch();
  const { cartItems } = useAppSelector((state) => state.cart);

  const [addToCart] = useAddProductCartMutation();

  const incrementQuantite = (item) => {
    addToCart({
      quantite: 1,
      produit: item?.produit?.id,
      prix: item?.prix,
      variations: item?.variations,
    });
  };

  const decrementQuantite = (item) => {
    addToCart({
      quantite: -1,
      produit: item?.produit?.id,
      prix: item?.prix,
      variations: item?.variations,
    });
  };

  // Fonction pour supprimer un produit du panier
  const supprimerDuPanier = async (produitASupprimer) => {
    await Swal.fire({
      title:
        "Êtes-vous sûr(e) de vouloir supprimer ce produit de votre panier ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      confirmButtonColor: Color.success,
      preConfirm: async () => {
        return await dispatch(removeCartItem(produitASupprimer));
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      // console.log(result, "archiver");

      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: `Produit supprimé avec succès !`,
          iconColor: Color.success,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };

  const actionFormatter = (cell: any, row: any) => {
    return (
      <>
        <div className="table-actions-btn-container-commandes d-flex justify-content-center align-items-center gap-3">
          {/* <div className="container-btn-action-icon-modal">
						<button
							className="btn btn-action-modal-icon with-tooltip"
							style={{ color: "#797A6F" }}
						>
							<AiOutlineHeart />
						</button>
					</div> */}
          <div className="container-btn-action-icon-modal">
            <button
              type="button"
              className="btn with-tooltip btn-action-icon-delete"
              data-tooltip-content="Supprimer"
              style={{ color: "#E53E3E" }}
              onClick={() => supprimerDuPanier(row)}
            >
              <FaTrash />
            </button>
          </div>
        </div>
      </>
    );
  };

  const productFormatter = (cell: Product, row: CartItemType) => {
    let imgProd = "";
    if (cell?.type === "taille_unique") {
      if (cell?.id) {
        if (cell?.images?.length) {
          imgProd = cell?.images[0]?.image;
        } else {
          imgProd = "";
        }
      }
    } else {
      if (row?.variations?.length) {
        let variation = row?.variations[0];
        if (variation?.images?.length) {
          imgProd = row?.variations[0]?.images[0]?.image;
        } else {
          if (cell?.images?.length) {
            imgProd = cell?.images[0]?.image;
          } else {
            imgProd = "";
          }
        }
      }
    }

    return (
      <div className="d-flex align-items-center gap-2">
        <div className="product-image-container">
          <img
            loading="lazy"
            src={imgProd ? getImage(imgProd) : Empty}
            alt="image"
            className="cart-image"
          />
        </div>
        <div>
          <p className="product-name-panier mb-1">{cell?.nom}</p>
          <p className="product-category-name mb-0"> {cell?.categorie?.nom}</p>
          <p className="product-category-name mb-0">
            {row?.variations[0] && row?.variations[0]?.taille}
          </p>
        </div>
      </div>
    );
  };

  const quantityFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <div className="row-quantity">
          {row?.quantite > 1 && (
            <button
              className="action-btn"
              type="button"
              onClick={() => dispatch(decrement(row))}
            >
              {" "}
              -
            </button>
          )}
          <span className="col-md-4 qty_wrapper">{row?.quantite}</span>
          {row?.quantite < row?.stock && (
            <button
              type="button"
              className="action-btn"
              onClick={() => dispatch(increment(row))}
            >
              {" "}
              +
            </button>
          )}
        </div>
      </div>
    );
  };

  const totalFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {formatCurrency((row?.prix * row?.quantite), Vendeur?.devise)}
      </div>
    );
  };

  const prixFormatter = (cell: any, row: any) => {
    return (
      <div className="d-flex align-items-center justify-content-center">
        {formatCurrency(row?.prix, Vendeur?.devise)}
      </div>
    );
  };

  const columns = [
    {
      dataField: "produit",
      text: "Produits",
      formatter: productFormatter,
      headerStyle: () => {
        return { width: "300px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "prix",
      text: "Prix",
      formatter: prixFormatter,
      headerStyle: () => {
        return { width: "160px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "quantite",
      text: "Quantité",
      formatter: quantityFormatter,
      headerStyle: () => {
        return { width: "200px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "total",
      text: "Total",
      formatter: totalFormatter,
      headerStyle: () => {
        return { width: "170px", whiteSpace: "normal" };
      },
    },
    {
      dataField: "id",
      text: "",
      formatter: actionFormatter,
    },
  ];

  return (
    <>
      <BootstrapTable
        keyField="produit.id"
        data={cartItems || []}
        columns={columns}
        condensed
        hover
        wrapperClasses="table-responsive panier-table"
        noDataIndication={() => <AlertInfo message="Votre panier est vide" />}
      />
    </>
  );
}

export default PanierItemTable;
