import React, { useEffect } from "react";
import {
  NavLink,
  Link,
  useNavigate,
  useSearchParams,
  useParams,
} from "react-router-dom";
import { useGetSearchProductListQuery } from "../../../utils/api/product/product.api";
import { formatCurrency, getImage  } from "../../../utils/Utils";
import { AlertInfo } from "../../common/Alert";
import Empty from "../../../assets/images/appImages/category.png";
import { getVendeur } from "../../HomePage/HomePage";


function SearchForm() {
  const Vendeur = getVendeur();

  const { slug } = useParams<{ slug: string }>();
  const [query, setQuery] = React.useState("");
  const navigate = useNavigate();
  const { data = { results: [] } } = useGetSearchProductListQuery({
    q: query,
  });

  useEffect(() => {
    setQuery("");
  }, [slug]);

  return (
    <div className="input-group-search relative-search-form">
      <div>
        <span className="input-group-btn">
          <i className="fa fa-search"></i>
        </span>
        <input
          type="text"
          className="form-control search-field"
          onChange={(e) => setQuery(e?.target?.value)}
          placeholder="Rechercher sur KARPOPHOREO"
        />
        {query && query?.length >= 3 && (
          <div className="list-container">
            <ul className="search-result-container">
              {data?.results?.length ? (
                data?.results?.map((item: any) => (
                  <li className="search-result-item" key={item?.id}>
                    <Link
                      to={`/produit/${item?.slug}`}
                      state={item}
                      key={`search${item?.id}`}
                      className="no-link"
                    >
                      <div className="row align-items-center">
                        <div className="col-sm-3 m-b-20 m-r-3">
                          <img
                            loading="lazy"
                            className="img-thumbs-commande-item"
                            src={getImage(
                              item?.type == "taille_unique"
                                ? item?.images[0]?.image
                                : item?.variations &&
                                  item?.variations[0]?.images
                                ? item?.variations[0]?.images[0]?.image
                                : Empty
                            )}
                            alt={item?.nom}
                            style={{
                              width: "75px",
                              height: "75px",
                            }}
                          />
                        </div>
                        <div className="pl-lg-0 col-sm-8 m-b-20">
                          <p className="name-prduct fs-15">{item?.nom}</p>
                          <div className="content-price-product-solde justify-content-start align-items-baseline">
                            <p className="price-product-item ">
                              {formatCurrency(item?.prix,Vendeur?.devise)}
                            </p>
                            {!!item?.id && (
                              <p className="price-solde-product-item mb-0">
                                {formatCurrency(item?.prix,Vendeur?.devise)}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                ))
              ) : (
                <li className="search-result-item">
                  <AlertInfo message="Aucun résultat trouvé!" />
                </li>
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchForm;
